<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Gérer les catégories des publications' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Liste des catégories' }" />
          </div>
          <div class="card-body">
             <!--<vb-controls-button-2
              :data="{ title: 'Ajouter catégorie', icon: 'fe fe-plus-circle', type: 'primary' }"
              @click="showModal"
            />-->
          </div>
            <div class="card-body">
                <publication-category-table 
                  :data="data"
                  :table-loading="tableLoading"
                  @changeData="changeData"
                />
            </div>
        </div>
      </div>
    </div>
    <!--<a-modal
      v-model:visible="visible"
      :width="300"
      title="Ajouter catégorie"
      @ok="onSubmit"
    >
        <a-form :model="formState" label-align="left" layout="vertical" @submit="onSubmit">
            <a-form-item label="Titre Fr">
               <a-input v-model:value="formState.title.fr" placeholder="Titre Fr" />
            </a-form-item>
            <a-form-item label="Titre Ar">
              <a-input v-model:value="formState.title.ar" placeholder="Titre Ar" />
            </a-form-item>
            <a-form-item label="Titre Ang">
              <a-input v-model:value="formState.title.en" placeholder="Titre Ang" />
            </a-form-item>        

        </a-form>    
    </a-modal>-->
  </div>
</template>

<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbHeadersCardHeaderTabbed from '@/components/widgets/Headers/CardHeader4'
import publicationCategoryTable from '@/components/widgets/TablesAntd/category_publications'
import ApiClient from "@/services/axios"
import { message } from 'ant-design-vue';
import { toRaw } from 'vue'

export default {
  name: 'VbPublications',  
  components: {
    VbHeadersTag,
    VbHeadersCardHeaderTabbed,
    publicationCategoryTable,
  },
  data(){      
      return{
          visible:false,
          data:[],
          tableLoading:false,
          formState:{
            title:{
              fr:"",
              ar:"",
              en:""
            },
          },
      }
  },
  created() {
      this.tableLoading=true
    ApiClient.post('/publications_cetegories/filter',{
      query:{}
    }).then((res)=>{
      this.data = res.data
    }).finally(()=>{
      this.tableLoading=false
    })
  },
  methods:{
      showModal(){
          this.visible = true
      },
      onSubmit(){
        const data = toRaw(this.formState)  
        ApiClient.put("/publications_cetegories",{
            data
        }).then((res)=>{
            this.data.push(res.data)
            message.success("Catégorie ajouté")
            this.visible = false
        }).catch(()=>(message.error("Impossible d'ajouter la catégorie")))
      },
      changeData(data){
        this.data = data
      }
  }
}
</script>
